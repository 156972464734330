import React from 'react';

import './HomeTopSection.css';
import { useTranslation } from 'react-i18next';
import image1 from '../Images/ground-spice-and-condiments-yhgjz564bnmufsdf.jpg';
import image2 from '../Images/organic-star-anise-rustic-dessert-decoration-generated-by-ai_188544-22969.avif';
import image3 from '../Images/pngtree-various-spices-and-spices-are-arranged-in-a-bowl-image_2956310.jpg';
import image4 from '../Images/istockphoto-1269840184-612x612.jpg';

const HomeTopSection = () => {
    const { t } = useTranslation();
    return (
        <div className="home-top-section">

            <div className="image-container">
                <img src={image1} alt="" className="animated-image" />
                <img src={image2} alt="" className="animated-image" />
                <img src={image3} alt="" className="animated-image" />
                <img src={image4} alt="" className="animated-image" />
                <div className="text-overlay">
                    <h1>{t('main_topic')} </h1>
                    <h1>{t('lanka')}</h1>
                    <p>{t('main_p1')}</p>
                    <p>{t('main_p2')}</p>
                </div>
            </div>
        </div>
    );
}

export default HomeTopSection;