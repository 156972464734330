import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.css';
import backgroundImage from '../Images/Footer/360_F_611464824_SJFGm3sPxRIGp3tyoSVxaTXb55rhHFqG.jpg'; // Ensure you provide the correct path
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="footer-overlay">
                <div className="footer-content">
                    <div className="left-section">
                        <h1>{t('company_name')}</h1>
                        <div className="social-media">
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                            <a href="https://wa.me/94764608225" target="_blank" rel="noopener noreferrer"><i className="fab fa-whatsapp"></i></a>
                            <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
                            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
                        </div>
                        <div className="contact-info">
                            <p className="address">
                                <i className="fas fa-map-marker-alt"></i>
                                {t('address')}
                            </p>
                            <p className="phone">
                                <i className="fas fa-phone-alt"></i>
                                {t('phone1')}<br />

                            </p>
                            <p className="email">
                                <i className="fas fa-envelope"></i>
                                {t('email1')}<br />

                            </p>
                        </div>
                    </div>
                    <div className="center-section">
                        <ul>
                            <li><Link to="/social-impact?section=history-section">{t('history_of_cinnamon')}</Link></li>
                            <li><Link to="/social-impact?section=info-section">{t('information_of_cinnamon')}</Link></li>
                            <li><Link to="/social-impact?section=benefits">{t('health_benefits_of_cinnamon')}</Link></li>
                        </ul>
                    </div>
                    <div className="right-section">
                        <ul>
                            <li><a href="/about">{t('who_we')}</a></li>
                            <li><a href="/social-impact">{t('cinnamon')}</a></li>
                            <li><a href="/products">{t('production')}</a></li>
                            <li><a href="/contact">{t('contact_me')}</a></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>{t('footer_text_1')}</p>
                    <p>{t('footer_text_2')}</p>
                    <p>
                        <a href="https://wa.me/94764608225" target="_blank" rel="noopener noreferrer">
                            {t('developed_by')}
                        </a>
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;