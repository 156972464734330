import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import { useTranslation } from 'react-i18next';
import './AboutUs.css';
import aboutTopImage from '../Images/About/l-intro-1622736354.jpg';
import rightColumnImage from '../Images/About/flatlay-spices-bowls-rusty-background-vertical-composition_651412-408.avif';
import storyImage from '../Images/high-angle-bowl-with-cinnamon-sticks-star-anise-pepper_23-2148426078.avif';
import Footer from '../components/Footer';

const AboutUs = () => {
    const { t } = useTranslation();

    useEffect(() => {
        const elements = document.querySelectorAll('.animate-popup');

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate-popup');
                    observer.unobserve(entry.target); // Stop observing after animation is triggered
                }
            });
        }, { threshold: 0.1 });

        elements.forEach(element => {
            observer.observe(element);
        });
    }, []);

    return (
        <div className="about-page-container">
            <div className="about-top-section">
                <img src={aboutTopImage} alt={t('About Top')} className="about-top-image" />
                <div className="about-top-text">
                    <h1>{t('about us')}</h1>
                    <p>{t('about_us_description')}</p>
                </div>
                <Navbar />
            </div>

            <div className="about-content-section">
                <div className="left-column animate-popup">
                    <h1>{t('who_we_are')}</h1>
                    <p>{t('who_we_are_desc1')}</p>
                    <p>{t('who_we_are_desc2')}</p>
                    <p>{t('who_we_are_desc3')}</p>
                    <p>{t('who_we_are_desc4')}</p>
                    <p>{t('who_we_are_desc5')}</p>
                    <p>{t('who_we_are_desc6')}</p>
                    <p>{t('who_we_are_desc7')}</p>
                </div>
                <div className="right-column animate-popup">
                    <img src={rightColumnImage} alt={t('Right Column')} className="right-column-image" />
                </div>
            </div>

            <div className="journey-section animate-popup">
                <h1>{t('our_journey')}</h1>
            </div>

            <div className="story-section animate-popup">
                <div className="story-left-column">
                    <img src={storyImage} alt={t('Our Story')} className="story-image" />
                </div>
                <div className="story-right-column">
                    <p>{t('our_story_paragraph1')}</p>
                    <p>{t('our_story_paragraph2')}</p>
                    <p>{t('our_story_paragraph3')}</p>
                    <p>{t('our_story_paragraph4')}</p>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default AboutUs;