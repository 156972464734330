// src/pages/SocialImpact.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from '../components/Navbar';
import './SocialImpact.css';
import Footer from '../components/Footer';
import { motion } from 'framer-motion';
import topImage from '../Images/Cinnamon/360_F_671264647_m21Tj2clzOth3B4zvxKv97IhnbYADv0S.jpg';
import historyImage from '../Images/Cinnamon/images.jpeg';
import infoImage from '../Images/Cinnamon/image.jpg';
import videoFile from '../Images/Cinnamon/5 Excellent Benefits Of Cinnamon - Cinnamon Tea Recipe.mp4';

const SocialImpact = () => {
    const { t } = useTranslation();

    return (
        <div>
            <div className='cinnamon-top-section'>
                <Navbar />
                <img src={topImage} alt="Social Impact Top" className="top-image" />
                <div className="cinnamon-top-text">
                    <h1>{t('social_impact_title')}</h1>
                    <p>{t('social_impact_description')}</p>
                </div>
            </div>
            <motion.div
                className="history-section"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
            >
                <div className="history-text">
                    <h1>{t('history_title')}</h1>
                    <ul>
                        <li>
                            <strong>{t('history_content.ancient_origins_title')}</strong>
                            <ul>
                                <li>{t('history_content.ancient_origins_1')}</li>
                                <li>{t('history_content.ancient_origins_2')}</li>
                            </ul>
                        </li>
                        <li>
                            <strong>{t('history_content.trade_exploration_title')}</strong>
                            <ul>
                                <li>{t('history_content.trade_exploration_1')}</li>
                                <li>{t('history_content.trade_exploration_2')}</li>
                            </ul>
                        </li>
                        <li>
                            <strong>{t('history_content.colonial_era_title')}</strong>
                            <ul>
                                <li>{t('history_content.colonial_era_1')}</li>
                                <li>{t('history_content.colonial_era_2')}</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <motion.div
                    className="history-image"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                >
                    <img src={historyImage} alt="History of Cinnamon" />
                </motion.div>
            </motion.div>
            <div className="info-section">
                <h1>{t('info_title')}</h1>
            </div>
            <motion.div
                className="cinnamon-info-section"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
            >
                <motion.div
                    className="info-image"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                >
                    <img src={infoImage} alt="Cinnamon Info" />
                </motion.div>
                <motion.div
                    className="info-text"
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                >
                    <h2>{t('botanical_profile_title')}</h2>
                    <ul>
                        <li>{t('botanical_profile.scientific_name')}</li>
                        <li>{t('botanical_profile.family')}</li>
                        <li>{t('botanical_profile.native_regions')}</li>
                    </ul>
                    <h2>{t('plant_description_title')}</h2>
                    <ul>
                        <li>{t('plant_description.appearance')}</li>
                        <li>{t('plant_description.bark')}</li>
                        <li>{t('plant_description.flowers_fruit')}</li>
                    </ul>
                    <h2>{t('cultivation_title')}</h2>
                    <ul>
                        <li>{t('cultivation.climate')}</li>
                        <li>{t('cultivation.soil')}</li>
                        <li>{t('cultivation.propagation')}</li>
                        <li>{t('cultivation.planting')}</li>
                    </ul>
                    <h2>{t('global_production_title')}</h2>
                    <ul>
                        <li>{t('global_production.major_producers')}</li>
                        <li>{t('global_production.trade_export')}</li>
                    </ul>
                    <h2>{t('industry_uses_title')}</h2>
                    <ul>
                        <li>{t('industry_uses.food_beverage')}</li>
                        <li>{t('industry_uses.pharmaceuticals')}</li>
                        <li>{t('industry_uses.cosmetics_perfumery')}</li>
                        <li>{t('industry_uses.agriculture')}</li>
                    </ul>

                </motion.div>
            </motion.div>
            <div className='benifts'>
                <h1>{t('benefits_title')}</h1>
            </div>
            <div className="video-section">
                <video autoPlay muted loop>
                    <source src={videoFile} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <Footer />
        </div>
    );
};

export default SocialImpact;