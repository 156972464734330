import React from 'react';
import './ProductModal.css';

const ProductModal = ({ image, name, description, onClose }) => {
    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>×</button>
                <img src={image} alt={name} className="modal-image" />
                <h2>{name}</h2>
                <p>{description}</p>
            </div>
        </div>
    );
}

export default ProductModal;
