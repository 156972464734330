import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaMapMarkerAlt } from 'react-icons/fa'; // Import the location icon from react-icons
import './Navbar.css';
import logo from '../Images/Navbar/3.png';

const Navbar = () => {
    const { t, i18n } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
    };

    const toggleMenu = () => {
        setIsMenuOpen((prevState) => !prevState);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <header className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
            <div className="navbar-content">
                <img src={logo} alt="Hiru Ceylon Logo" className="logo" />
                <button className="menu-toggle" onClick={toggleMenu}>
                    ☰ {t('menu')}
                </button>
                <div className={`menu-items ${isMenuOpen ? 'open' : ''} ${isScrolled ? 'scrolled-menu' : ''}`}>
                    <Link to="/" onClick={closeMenu}>{t('home')}</Link>
                    <Link to="/about" onClick={closeMenu}>{t('about_us')}</Link>
                    <Link to="/social-impact" onClick={closeMenu}>{t('social_impact')}</Link>
                    <Link to="/products" onClick={closeMenu}>{t('our_products')}</Link>
                    <Link to="/contact" onClick={closeMenu}>{t('contact_us')}</Link>
                    <a href="https://wa.me/94764608225" className="contact-button">{t('contact')}</a>
                    <a href="https://www.google.com/maps/place/Sri+Lanka" className="location-link">
                        <FaMapMarkerAlt className="location-icon" /> {t('location')}
                    </a>
                    <select onChange={(e) => handleLanguageChange(e.target.value)} className="language-select">
                        <option value="en">English</option>
                        <option value="de">German</option>
                        <option value="nl">Dutch</option>
                    </select>
                </div>
            </div>
            {isMenuOpen && (
                <div className="mobile-menu">
                    <button className="close-menu" onClick={toggleMenu}>×</button>
                    <div className="mobile-menu-items">
                        <Link to="/" onClick={closeMenu}>{t('home')}</Link>
                        <Link to="/about" onClick={closeMenu}>{t('about_us')}</Link>
                        <Link to="/social-impact" onClick={closeMenu}>{t('social_impact')}</Link>
                        <Link to="/products" onClick={closeMenu}>{t('our_products')}</Link>
                        <Link to="/contact" onClick={closeMenu}>{t('contact_us')}</Link>
                        <a href="https://wa.me/4915781519404" className="contact-button">{t('contact')}</a>
                        <a href="https://maps.app.goo.gl/nAJXw2YojMEXCXkEA" className="location-link">
                            <FaMapMarkerAlt className="location-icon" /> {t('location')}
                        </a>
                        <select onChange={(e) => handleLanguageChange(e.target.value)} className="language-select">
                            <option value="en">English</option>
                            <option value="de">German</option>
                            <option value="nl">Dutch</option>
                        </select>
                    </div>
                </div>
            )}
        </header>
    );
};

export default Navbar;