import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import SocialImpact from './pages/SocialImpact';
import OurProducts from './pages/OurProducts';
import ContactUs from './pages/ContactUs';
import './App.css';

function App() {
  return (
    <Router>
      <div className="app-container">
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/social-impact" element={<SocialImpact />} />
            <Route path="/products" element={<OurProducts />} />
            <Route path="/contact" element={<ContactUs />} />
          </Routes>
        </div>

      </div>
    </Router>
  );
}

export default App;