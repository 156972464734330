import React, { useState } from 'react';
import './Product.css';
import ProductModal from '../components/ProductModal';

const Product = ({ image, category, name, description }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleViewProduct = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="product-card">
            <img src={image} alt={name} className="product-image" />
            <p>{category}</p>
            <h3>{name}</h3>
            <button onClick={handleViewProduct}>
                View Product
            </button>
            {isModalOpen && (
                <ProductModal
                    image={image}
                    name={name}
                    description={description}
                    onClose={closeModal}
                />
            )}
        </div>
    );
}

export default Product;
