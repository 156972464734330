import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import { useTranslation } from 'react-i18next';
import Product from '../components/Product';

import Footer from '../components/Footer';
import './OurProducts.css';
import productTopImage from '../Images/Products/istockphoto-941858854-612x612.jpg';
import product1 from '../Images/Products/Cinnamon/cinnamon-off-cut.jpg';
import product2 from '../Images/Products/Cinnamon/quillings-01.webp';
import product3 from '../Images/Products/Cinnamon/12h1-1-1.jpg';
import product4 from '../Images/Products/Cinnamon/LS1010CF51-09-E.webp';
import product5 from '../Images/Products/Cinnamon/LS1010E255-09-E.webp';
import product6 from '../Images/Products/Cinnamon/images.jpeg';
import product7 from '../Images/Products/Cinnamon/C5-Special-min-1024x1024.jpg';
import product8 from '../Images/Products/Cinnamon/12c5-special.jpg';
import product9 from '../Images/Products/Cinnamon/LS1010F11A-01-E.webp';
import product10 from '../Images/Products/Cinnamon/alba-tiny-png.webp';
import product11 from '../Images/Products/Cinnamon/h2-cinnamon.webp';
import product12 from '../Images/Products/Whole Spices/Black-Pepper-1.webp';
import product13 from '../Images/Products/Whole Spices/nutmeg-whole-organic.jpg';
import product14 from '../Images/Products/Whole Spices/images.jpeg';
import product15 from '../Images/Products/Whole Spices/whole-mace-600x600.jpg';
import product16 from '../Images/Products/Whole Spices/il_fullxfull.3560581494_k1we.webp';
import product17 from '../Images/Products/Whole Spices/natural-green-pepper-500x500.webp';
import product18 from '../Images/Products/Whole Spices/71lJRh4B+9L.jpg';
import product19 from '../Images/Products/Whole Spices/clove-stems.png';
import product20 from '../Images/Products/Spices Powder/cinnamon-true-ceylon-powder-organic.jpg';
import product21 from '../Images/Products/Spices Powder/a6decc09-f63a-432e-af66-3a944e331128.jpg';
import product22 from '../Images/Products/Spices Powder/white-ground-pepper.webp';
import product23 from '../Images/Products/Spices Powder/large_Ginger Powder.png';
import product24 from '../Images/Products/Spices Powder/thewholesalerco-Clove-Powder.webp';

const OurProducts = () => {
    const { t } = useTranslation();
    const [selectedCategory, setSelectedCategory] = useState('All');

    const products = [
        { id: 1, image: product1, category: 'Cinnamon', name: 'Cinnamon Off Cut', description: 'Cinnamon Off Cut consists of the smaller, less uniform pieces of cinnamon bark that are leftover from the production of high-quality cinnamon sticks. Despite their appearance, these off cuts are highly flavorful and rich in essential oils, making them an excellent, cost-effective choice for infusing foods and beverages with the aromatic essence of cinnamon. They are commonly used in teas, syrups, and cooking, as well as in the production of essential oils and fragrances, providing a sustainable way to utilize every part of the cinnamon harvest while reducing waste.' },
        { id: 2, image: product2, category: 'Cinnamon', name: 'Cinnamon Quillings No. 02', description: 'Cinnamon Quillings No. 02 is a premium culinary product made from finely crafted, high-quality cinnamon quills. These quills are meticulously selected for their rich aroma and distinctive sweet-spicy flavor, perfect for enhancing the taste of both sweet and savory dishes. Ideal for gourmet cooking and baking, Cinnamon Quillings No. 02 can be used whole or ground to add depth and warmth to recipes. Their elegant appearance also makes them suitable for decorative purposes in food presentation or as a fragrant addition to potpourri blends.' },
        { id: 3, image: product3, category: 'Cinnamon', name: 'Cinnamon H2 Super', description: 'Cinnamon H2 Super is an exceptional variety of cinnamon known for its potent flavor and robust aroma. Harvested from carefully cultivated cinnamon trees, this premium grade offers a superior taste experience, making it a favorite among culinary enthusiasts and professional chefs. Its high oil content and fine texture ensure a smooth and intense infusion of flavor in a wide range of dishes, from desserts and beverages to savory meals. Cinnamon H2 Super is also valued for its potential health benefits, including its antioxidant properties and ability to support metabolic health.' },
        { id: 4, image: product4, category: 'Cinnamon', name: 'Cinnamon M4', description: 'Cinnamon M4 is a distinguished grade of cinnamon recognized for its balanced flavor and aromatic qualities. Sourced from select plantations, this variety offers a subtle yet complex taste profile that complements both sweet and savory culinary creations. Its fine grind and consistent quality make it a versatile ingredient in baking, cooking, and beverages, enhancing recipes with a warm, spicy note. Cinnamon M4 is also noted for its potential health benefits, including anti-inflammatory properties and aiding in blood sugar regulation, making it a valuable addition to a health-conscious diet.' },
        { id: 5, image: product5, category: 'Cinnamon', name: 'Cinnamon M5', description: 'Cinnamon M5 is a top-tier cinnamon variety celebrated for its intense flavor and rich fragrance. Carefully harvested and processed, this grade offers a slightly more pronounced and robust taste compared to other varieties, making it ideal for dishes that require a strong cinnamon presence. Its fine texture ensures even distribution in recipes, whether in baking, cooking, or beverage preparation. Cinnamon M5 is not only prized for its culinary versatility but also for its health benefits, which include antioxidant properties and support for metabolic health, making it a preferred choice for both chefs and health enthusiasts alike.' },
        { id: 6, image: product6, category: 'Cinnamon', name: 'Cinnamon C4', description: 'Cinnamon C4 is a high-quality cinnamon variant known for its refined, delicate flavor and fragrant aroma. This grade is selected from premium cinnamon sources, ensuring a consistent and smooth taste that is perfect for a variety of culinary applications. Whether used in baking, cooking, or beverages, Cinnamon C4 imparts a warm and sweet-spicy note that enhances the overall flavor profile of dishes. Its versatility makes it a staple in both professional and home kitchens. Additionally, Cinnamon C4 is valued for its health benefits, such as its antioxidant and anti-inflammatory properties, contributing to overall well-being.' },
        { id: 7, image: product7, category: 'Cinnamon', name: 'Cinnamon C5', description: 'Cinnamon C5 is a premium grade of cinnamon revered for its robust and aromatic profile. Handpicked from select cinnamon trees, this variety offers a rich and spicy flavor that stands out in both sweet and savory dishes. Its fine, uniform texture makes it ideal for use in baking, cooking, and beverage preparation, ensuring an even and potent distribution of flavor. Cinnamon C5 is particularly prized by chefs and home cooks who seek a strong, pronounced cinnamon taste. Beyond its culinary uses, Cinnamon C5 is also appreciated for its health benefits, including its antioxidant properties and its potential to aid in blood sugar regulation.' },
        { id: 8, image: product8, category: 'Cinnamon', name: 'Cinnamon C5 Special', description: 'Cinnamon C5 Special is a distinguished and high-end variety of cinnamon, celebrated for its exceptionally rich flavor and intense aroma. Sourced from carefully selected cinnamon trees, this grade offers a unique and robust taste experience, making it a preferred choice for gourmet chefs and culinary enthusiasts. The fine, consistent texture of Cinnamon C5 Special ensures it integrates seamlessly into a variety of dishes, from baked goods to savory meals and beverages. Its strong, spicy-sweet profile enhances the complexity of recipes, while its antioxidant and anti-inflammatory properties provide additional health benefits. This premium cinnamon is ideal for those seeking superior quality and flavor in their culinary creations.' },
        { id: 9, image: product9, category: 'Cinnamon', name: 'Cinnamon Extra Special', description: 'Cinnamon Extra Special is a premium grade cinnamon renowned for its exceptional quality and unparalleled flavor profile. This top-tier cinnamon is carefully harvested and processed to ensure an intense, aromatic experience with a sweet-spicy taste that elevates any dish. Its fine texture and consistent quality make it a standout ingredient in both gourmet and everyday cooking, enhancing recipes with a deep, rich flavor. Ideal for high-end baking, cooking, and beverages, Cinnamon Extra Special also boasts potential health benefits, such as its antioxidant and anti-inflammatory properties, making it a luxurious and beneficial addition to any culinary repertoire.' },
        { id: 10, image: product10, category: 'Cinnamon', name: 'Cinnamon Alba', description: 'Cinnamon Alba is a high-quality cinnamon variety known for its superior flavor and aromatic richness. Sourced from carefully cultivated cinnamon trees, Alba offers a refined and delicate taste with subtle notes of sweetness and spice. Its fine texture and consistent quality make it ideal for enhancing a wide range of dishes, from baked goods and desserts to savory recipes and beverages. Cinnamon Alba is valued for its elegant flavor profile and versatility, making it a favorite among chefs and home cooks who seek a premium cinnamon experience. Additionally, it is appreciated for its potential health benefits, including antioxidant properties and support for overall well-being.' },
        { id: 11, image: product11, category: 'Cinnamon', name: 'Cinnamon H2', description: 'Cinnamon H2 is a high-quality cinnamon variety known for its robust and aromatic flavor. Carefully sourced from premium cinnamon trees, this grade features a rich, spicy taste that enhances both sweet and savory dishes. Its fine texture ensures even distribution and integration into recipes, making it suitable for a wide range of culinary applications, including baking, cooking, and beverage preparation. Cinnamon H2 is also appreciated for its potential health benefits, such as antioxidant properties and support for metabolic health, adding both flavor and wellness benefits to any dish.' },
        { id: 12, image: product12, category: 'Whole Spices', name: 'Black Pepper', description: 'Black pepper is one of the most widely used spices globally, known for its sharp, pungent flavor and aromatic qualities. Derived from the dried berries of the Piper nigrum plant, black pepper is often used to season and enhance the taste of a wide variety of dishes. Its versatile flavor profile makes it a staple in both savory and some sweet recipes. In addition to its culinary uses, black pepper is valued for its potential health benefits, including its antioxidant properties, digestive aid, and potential anti-inflammatory effects. Its bold, spicy kick makes it a key ingredient in many spice blends and a go-to seasoning in kitchens around the world.' },
        { id: 13, image: product13, category: 'Whole Spices', name: 'Nutmeg', description: 'Nutmeg is a warm, aromatic spice derived from the seed of the nutmeg tree (Myristica fragrans). It has a distinctive sweet and slightly spicy flavor that adds depth and complexity to both sweet and savory dishes. Nutmeg is commonly used in baking, desserts, and beverages like eggnog, as well as in savory dishes such as soups, stews, and sauces. Beyond its culinary uses, nutmeg is also known for its potential health benefits, including digestive support, anti-inflammatory properties, and its role as a mild sedative. Its rich and versatile flavor makes it a cherished ingredient in various global cuisines.' },
        { id: 14, image: product14, category: 'Whole Spices', name: 'Cloves', description: 'Cloves are aromatic flower buds from the clove tree (Syzygium aromaticum), known for their intense, warm, and slightly sweet flavor. They are a key ingredient in many spice blends and are used to season both sweet and savory dishes, such as baked goods, stews, and marinades. Cloves are also valued for their potential health benefits, including antioxidant properties, anti-inflammatory effects, and digestive support. Their strong, distinctive taste and fragrant aroma make them a popular choice in cooking and baking, as well as in traditional medicine and herbal remedies.' },
        { id: 15, image: product15, category: 'Whole Spices', name: 'Mace', description: 'Mace is a spice derived from the reddish, lacy outer layer (aril) of the nutmeg seed. It has a flavor profile that is similar to nutmeg but with a more delicate, slightly peppery taste and a subtle warmth. Mace is used in both sweet and savory dishes, including baked goods, sauces, soups, and stews. Its aromatic qualities make it a valuable ingredient in spice blends and flavorings. In addition to its culinary uses, mace has potential health benefits, such as digestive support and antioxidant properties, and has been used in traditional medicine for its soothing and anti-inflammatory effects.' },
        { id: 16, image: product16, category: 'Whole Spices', name: 'Betel Nuts', description: 'Betel nuts are the seeds of the betel palm (Areca catechu) and are commonly chewed in many cultures, especially in South and Southeast Asia, often wrapped in betel leaves along with slaked lime and sometimes other spices. The nuts have a strong, slightly bitter flavor and are known for their stimulant effects, providing a sense of alertness and increased energy. In addition to their use in traditional chewing practices, betel nuts are sometimes used in ceremonial and cultural contexts. However, regular consumption of betel nuts has been associated with various health risks, including oral cancer and other serious health issues, due to their carcinogenic properties.' },
        { id: 17, image: product17, category: 'Whole Spices', name: 'Dehydrated Green Pepper', description: 'Dehydrated green pepper is made from green bell peppers that have been dried to remove moisture, concentrating their flavor and preserving their vibrant color. This spice offers a milder, less pungent taste compared to black pepper and is used to add a sweet and slightly tangy pepper flavor to dishes. It’s ideal for seasoning soups, stews, sauces, and marinades, as well as for enhancing the flavor of dry rubs and spice blends. Dehydrated green pepper is convenient for long-term storage and provides a burst of fresh pepper flavor when rehydrated or used in its dried form.' },
        { id: 18, image: product18, category: 'Whole Spices', name: 'White Pepper', description: 'White pepper is made from the dried seeds of ripe pepper berries (Piper nigrum) with the outer skin removed, resulting in a smoother, less pungent flavor compared to black pepper. It has a milder, slightly earthy taste and is often used in dishes where a less visible seasoning is preferred, such as in white sauces, soups, and light-colored dishes. White pepper is also valued for its subtle heat and ability to blend seamlessly into various recipes. It’s a common ingredient in Asian cuisine and provides a sophisticated, less aggressive pepper flavor than its black counterpart.' },
        { id: 19, image: product19, category: 'Whole Spices', name: 'Clove Stems', description: 'Clove stems are the woody, elongated parts of the clove plant that remain after the flower buds (cloves) are harvested. While they are not typically used as a spice on their own, they are sometimes employed in cooking for their subtle clove flavor, particularly in slow-cooked dishes where they can infuse their aromatic qualities over time. Clove stems are often used to add depth to broths, stews, and marinades. In some cultures, they may also be used in traditional medicine or as part of aromatic blends. However, their flavor is generally milder and less concentrated compared to the actual clove buds.' },
        { id: 20, image: product20, category: 'Spices Powder', name: 'Cinnamon Powder', description: 'Cinnamon powder is a finely ground spice made from the dried bark of cinnamon trees, typically from the Cinnamomum verum or Cinnamomum cassia species. It has a warm, sweet, and slightly spicy flavor that enhances both sweet and savory dishes. Commonly used in baking, desserts, and beverages like cinnamon rolls, cookies, and mulled wine, cinnamon powder also adds a comforting depth to savory dishes such as curries and stews. Beyond its culinary uses, cinnamon powder is valued for its potential health benefits, including antioxidant properties, anti-inflammatory effects, and blood sugar regulation. Its versatility and rich aroma make it a staple in kitchens around the world.' },
        { id: 21, image: product21, category: 'Spices Powder', name: 'Nutmeg Powder', description: 'Nutmeg powder is a spice made by grinding the dried seed of the nutmeg tree (Myristica fragrans). It has a warm, sweet, and slightly spicy flavor with hints of earthiness, making it a popular addition to both sweet and savory dishes. Nutmeg powder is commonly used in baking for cakes, cookies, and pies, as well as in beverages like eggnog and mulled wine. It also enhances savory dishes such as soups, stews, and sauces. In addition to its culinary uses, nutmeg powder is noted for its potential health benefits, including digestive support, anti-inflammatory properties, and its ability to act as a mild sedative. Its aromatic qualities and versatility make it a cherished ingredient in various recipes.' },
        { id: 22, image: product22, category: 'Spices Powder', name: 'White Pepper Powder', description: 'White pepper powder is made from the seeds of the ripe pepper berries (Piper nigrum), with the outer skin removed. This process results in a smooth, fine powder with a milder and less pungent flavor compared to black pepper. It has a subtle, earthy heat that enhances the taste of dishes without adding visible specks of pepper, making it ideal for light-colored sauces, soups, and dishes where a more refined pepper flavor is desired. White pepper powder is commonly used in Asian cuisine and other dishes where a smoother, less aggressive pepper taste is preferred.' },
        { id: 23, image: product23, category: 'Spices Powder', name: 'Ginger Powder', description: 'Ginger powder is a spice made from dried, ground ginger root (Zingiber officinale). It has a warm, spicy, and slightly sweet flavor with a hint of earthiness, making it a versatile ingredient in both sweet and savory dishes. Ginger powder is commonly used in baking for recipes such as gingerbread and cookies, as well as in savory dishes like curries, stir-fries, and marinades. It is also a popular ingredient in beverages like chai tea and ginger ale. Beyond its culinary uses, ginger powder is valued for its potential health benefits, including its anti-inflammatory properties, digestive support, and its ability to help with nausea and motion sickness.' },
        { id: 24, image: product24, category: 'Spices Powder', name: 'Clove Powder', description: 'Clove powder is made by grinding dried clove buds (Syzygium aromaticum) into a fine, aromatic powder. It has a strong, warm, and slightly sweet flavor with a distinctive, pungent aroma. Clove powder is used in a variety of dishes, including baked goods, stews, curries, and spice blends, to impart a rich and spicy depth. It is also a key ingredient in many holiday recipes, such as pumpkin pie and mulled wine. In addition to its culinary uses, clove powder is known for its potential health benefits, including antioxidant properties, anti-inflammatory effects, and digestive support. Its potent flavor means a little goes a long way in enhancing recipes.' }
    ];

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    };

    const filteredProducts = selectedCategory === 'All' ? products : products.filter(product => product.category === selectedCategory);

    return (
        <>
            <Navbar />
            <div className="product-top-section">
                <img src={productTopImage} alt="Products" className="product-top-image" />
                <div className="product-top-text">
                    <h1>{t('ourProducts')}</h1>
                    <p>{t('about_us_description')}</p>
                </div>
            </div>
            <div className="production">
                <h1>{t('produ_ction')}</h1>
                <div className="products-buttons-section">
                    <button onClick={() => handleCategoryChange('All')}>{t('all')}</button>
                    <button onClick={() => handleCategoryChange('Cinnamon')}>{t('cinn_amon')}</button>
                    <button onClick={() => handleCategoryChange('Whole Spices')}>{t('wholeSpices')}</button>
                    <button onClick={() => handleCategoryChange('Spices Powder')}>{t('spicesPowder')}</button>
                </div>
                <div className="products-section">
                    {filteredProducts.map(product => (
                        <Product
                            key={product.id}
                            image={product.image}
                            category={product.category}
                            name={product.name}
                            description={product.description}
                        />
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
}

export default OurProducts;
