import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HomeTopSection from '../components/HomeTopSection';
import './Home.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { motion } from 'framer-motion';
import welcomeImage from '../Images/istockphoto-497454523-612x612.jpg';
import cinnamonImage1 from '../Images/cinnamon-sticks-on-wooden-background-600nw-2180121165.webp';
import cinnamonImage2 from '../Images/istockphoto-497454523-612x612.jpg';
import fullWidthImage from '../Images/cinnamon-sticks-black-background_978921-465.avif';
import transparentImage from '../Images/467cadbfc6687572b0d3df0616cb0622.svg';
import fourImage1 from '../Images/group-of-people-joining-hands-together-on-black-background-closeup-stack-of-hands-showing-unity-and-teamwork-ai-generated-free-photo.jpg';
import fourImage2 from '../Images/cinnamon-benefits44-724deaf.jpg';
import fourImage3 from '../Images/470-x-490.jpg';
import fourImage4 from '../Images/top-view-dark-modern-workplace-with-smartphone-tablet-office-supplies-copy-space_67155-5909.avif';
import manufacturingVideo from '../Images/spices-varieties-video-design-template-40fe70f726793cac50128762b506a24e_screen.mp4';
import deliveryIcon from '../Images/png-transparent-delivery-computer-icons-courier-business-buying-and-selling-text-logo-cargo.png';
import chatIcon from '../Images/pngtree-vector-chat-icon-png-image_3788098.jpg';
import sustainableIcon from '../Images/images.png';
import growthIcon from '../Images/pngtree-business-growth-line-icon-png-image_9137889.png';
import newImage from '../Images/360_F_565999058_HxQFj34ifaVeFGpbWIsImPz1GfedqYOO.jpg';
import faqImage from '../Images/Five-Spice-Powder-articleLarge.jpg';
import otherCompanyImage1 from '../Images/boganvilla/7.jpeg';
import otherCompanyImage2 from '../Images/boganvilla/8.jpeg';
import otherCompanyImage3 from '../Images/boganvilla/2.jpeg';
import otherCompanyImage4 from '../Images/boganvilla/3.jpeg';
import otherCompanyImage5 from '../Images/boganvilla/4.jpeg';
import otherCompanyImage6 from '../Images/boganvilla/5.jpeg';
import otherCompanyImage7 from '../Images/boganvilla/6.jpeg';
import otherCompanyImage8 from '../Images/boganvilla/9.jpeg';
import otherCompanyImage9 from '../Images/boganvilla/10.jpeg';
import otherCompanyImage10 from '../Images/boganvilla/11.jpeg';
import otherCompanyImage11 from '../Images/boganvilla/12.jpeg';
import otherCompanyImage12 from '../Images/boganvilla/13.jpeg';
import otherCompanyImage13 from '../Images/boganvilla/WhatsApp Image 2024-07-28 at 12.32.00.jpeg';
import otherCompanyImage14 from '../Images/boganvilla/2.jpeg';
import otherCompanyImage15 from '../Images/boganvilla/10.jpeg';



const feedbacks = [
    {
        feedback: {
            en: "Hiru Ceylon spices elevate every dish with their unparalleled quality and rich aroma. Highly recommended!",
            de: "Die Gewürze von Hiru Ceylon heben jedes Gericht durch ihre unübertroffene Qualität und das reiche Aroma hervor. Sehr empfehlenswert!",
            nl: "De specerijen van Hiru Ceylon tillen elk gerecht naar een hoger niveau met hun ongeëvenaarde kwaliteit en rijke aroma. Sterk aanbevolen!"
        },
        name: {
            en: "John David",
            de: "John David",
            nl: "John David"
        },
        role: {
            en: "Customer",
            de: "Kunde",
            nl: "Klant"
        }
    },
    {
        feedback: {
            en: "The freshness and potency of Hiru Ceylon spices have transformed my cooking experience. Truly exceptional!",
            de: "Die Frische und Potenz der Gewürze von Hiru Ceylon haben mein Kocherlebnis verändert. Wirklich außergewöhnlich!",
            nl: "De versheid en kracht van de specerijen van Hiru Ceylon hebben mijn kookervaring veranderd. Echt uitzonderlijk!"
        },
        name: {
            en: "Manjula Fernando",
            de: "Manjula Fernando",
            nl: "Manjula Fernando"
        },
        role: {
            en: "Customer",
            de: "Kunde",
            nl: "Klant"
        }
    },
    {
        feedback: {
            en: "Hiru Ceylon spices offer an extraordinary flavor profile that enhances every meal. The quality is unbeatable.",
            de: "Die Gewürze von Hiru Ceylon bieten ein außergewöhnliches Geschmacksprofil, das jede Mahlzeit bereichert. Die Qualität ist unübertroffen.",
            nl: "De specerijen van Hiru Ceylon bieden een buitengewoon smaakprofiel dat elke maaltijd verrijkt. De kwaliteit is ongeëvenaard."
        },
        name: {
            en: "Aruna Perera",
            de: "Aruna Perera",
            nl: "Aruna Perera"
        },
        role: {
            en: "Customer",
            de: "Kunde",
            nl: "Klant"
        }
    },
    {
        feedback: {
            en: "Every spice from Hiru Ceylon exudes top-notch quality and a unique aroma that makes my dishes stand out.",
            de: "Jedes Gewürz von Hiru Ceylon strahlt erstklassige Qualität und ein einzigartiges Aroma aus, das meine Gerichte hervorhebt.",
            nl: "Elke specerij van Hiru Ceylon straalt topkwaliteit en een uniek aroma uit, waardoor mijn gerechten opvallen."
        },
        name: {
            en: "Kalum Rathnayaka",
            de: "Kalum Rathnayaka",
            nl: "Kalum Rathnayaka"
        },
        role: {
            en: "Customer",
            de: "Kunde",
            nl: "Klant"
        }
    },
    {
        feedback: {
            en: "The remarkable quality and rich aroma of Hiru Ceylon spices make them a staple in my kitchen. Highly satisfied!",
            de: "Die bemerkenswerte Qualität und das reiche Aroma der Gewürze von Hiru Ceylon machen sie zu einem Grundnahrungsmittel in meiner Küche. Sehr zufrieden!",
            nl: "De opmerkelijke kwaliteit en het rijke aroma van de specerijen van Hiru Ceylon maken ze een vast onderdeel van mijn keuken. Zeer tevreden!"
        },
        name: {
            en: "Ashan Perera",
            de: "Ashan Perera",
            nl: "Ashan Perera"
        },
        role: {
            en: "Customer",
            de: "Kunde",
            nl: "Klant"
        }
    },
    {
        feedback: {
            en: "Hiru Ceylon spices are of exceptional quality, delivering an unmatched aroma and taste to all my recipes.",
            de: "Die Gewürze von Hiru Ceylon sind von außergewöhnlicher Qualität und verleihen allen meinen Rezepten ein unübertroffenes Aroma und Geschmack.",
            nl: "De specerijen van Hiru Ceylon zijn van uitzonderlijke kwaliteit en geven al mijn recepten een ongeëvenaard aroma en smaak."
        },
        name: {
            en: "Christopher Fernando",
            de: "Christopher Fernando",
            nl: "Christopher Fernando"
        },
        role: {
            en: "Customer",
            de: "Kunde",
            nl: "Klant"
        }
    }
];

const faqs = [
    {
        question: "What makes Sri Lankan cinnamon unique, and why is it renowned?",
        answer: "Sri Lankan cinnamon, or Ceylon cinnamon, is the premium variety of cinnamon, exclusively grown in Sri Lanka. Known for its sweet and subtle flavor, it is highly prized for culinary use. We ensure our customers receive only the finest quality Sri Lankan cinnamon."
    },
    {
        question: "What grades of Sri Lankan cinnamon does the company provide?",
        answer: "Our company provides a range of premium Sri Lankan cinnamon grades, including Alba, C5, M5, and H. Alba is the highest grade, known for its superior quality. We take pride in offering these diverse grades to meet various preferences."
    },
    {
        question: "What are the health benefits of Sri Lankan cinnamon?",
        answer: "Sri Lankan cinnamon, distinct from cassia, boasts unique qualities and offers numerous benefits such as anti-inflammatory, antioxidant, and antibacterial properties. It helps regulate blood sugar, lower cholesterol, and enhance brain function. By using only the finest Sri Lankan cinnamon in our products, we provide our customers with these exceptional health benefits."
    },
    {
        question: "What are some of the popular Sri Lankan spices available from the company?",
        answer: "Our company provides a wide range of Sri Lankan spices, such as Nutmeg, Black Pepper, Cardamom, Ginger, and other local varieties. We meticulously source these spices from certified organic and fair trade farms to ensure top quality and ethical sourcing."
    },
    {
        question: "Does Hiru Ceylon Spices prioritize ethical and sustainable practices in sourcing its spices?",
        answer: "Yes, indeed. Our company is dedicated to sourcing spices from certified organic and fair trade farms, ensuring fair compensation for farmers. We support sustainable and ethical practices to help preserve Sri Lanka’s unique and flavorful spices for future generations."
    },
    {
        question: "How should I store my spices and cinnamon to maintain their freshness?",
        answer: "We advise storing Sri Lankan spices and cinnamon in airtight containers in a cool, dry location, away from direct sunlight and heat. This helps preserve their freshness and flavor for an extended period."
    },
    {
        question: "Can Sri Lankan spices be used in recipes beyond traditional Sri Lankan dishes?",
        answer: "Absolutely! Our Sri Lankan spices are versatile and can enhance a wide range of dishes, from Latin American and European to Middle Eastern and Asian cuisines. We believe these spices should be enjoyed across various cooking styles, and we’re proud to offer them for diverse culinary creations."
    },
    {
        question: "Does Hiru Ceylon Spices provide wholesale pricing for Sri Lankan spices and cinnamon?",
        answer: "Yes, Hiru Ceylon Spices offers wholesale pricing for bulk orders of Sri Lankan spices and cinnamon. We also provide retail options and private labeling services. Contact us to learn more about our wholesale rates and explore our retail and private label solutions."
    },
    {
        question: "Does Hiru Ceylon Spices offer custom packaging options for bulk orders?",
        answer: "Yes, Hiru Ceylon Spices provides custom packaging options for bulk orders. We can tailor packaging to meet your specific needs, whether for retail or wholesale. Reach out to us for details on how we can customize packaging to fit your requirements.",
    },
    {
        question: "Can I get a sample of Sri Lankan spices before placing a large order?",
        answer: "Absolutely! Hiru Ceylon Spices offers sample packs of our Sri Lankan spices for you to try before making a large purchase. Contact us to request a sample and experience the quality of our spices firsthand.",
    },
];

const Home = () => {
    const { t, i18n } = useTranslation();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [activeIndex, setActiveIndex] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 3) % feedbacks.length);
        }, 5000); // Change every 5 seconds
        return () => clearInterval(interval);
    }, []);

    const feedbacksToDisplay = feedbacks.slice(currentIndex, currentIndex + 3);

    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div>
            <HomeTopSection />
            <Navbar />
            <div className='welcome-section'>
                <div className="left-column">
                    <h1>{t('WELCOME_TO_HIRU_CEYLON')}</h1>
                    <p>{t('welcome_p1')}</p>
                    <p>{t('welcome_p2')}</p>
                </div>
                <div className="right-column">
                    <img src={welcomeImage} alt="Welcome" />
                </div>
            </div>

            <div className="Cinnamon">
                <h1>{t('Health_Benefits_Of_Cinnamon')}</h1>
            </div>

            <div className='benefits-section'>
                <div className="left-column">
                    <img src={cinnamonImage1} alt="Cinnamon" />
                    <img src={cinnamonImage2} alt="Cinnamon" />
                </div>
                <div className="right-column">
                    <h1>{t('benefits_intro')}</h1>
                    <ul>
                        <li><strong>{t('benefits.antioxidant_properties')}</strong></li>
                        <li><strong>{t('benefits.anti_inflammatory_effects')}</strong></li>
                        <li><strong>{t('benefits.regulates_blood_sugar')}</strong></li>
                        <li><strong>{t('benefits.antimicrobial_activity')}</strong></li>
                        <li><strong>{t('benefits.heart_health')}</strong></li>
                        <li><strong>{t('benefits.brain_function')}</strong></li>
                        <li><strong>{t('benefits.digestive_health')}</strong></li>
                        <li><strong>{t('benefits.weight_management')}</strong></li>
                    </ul>
                </div>
            </div>


            <div className="worldwide-section">
                <h1>{t('worldwide_usage_title')}</h1>
            </div>

            <div className="image-section">
                <img src={fullWidthImage} alt="Full Width" className="full-width-image" />
                <img src={transparentImage} alt="Transparent" className="transparent-image" />
            </div>
            <div className="four-images-section">
                <a href="/about">
                    <img src={fourImage1} alt="About Us" className="four-image" />
                    <h2 className="image-title">{t('who_we_are_title')}</h2>
                </a>
                <a href="/social-impact">
                    <img src={fourImage2} alt="Social Impact" className="four-image" />
                    <h2 className="image-title">{t('cinnamon_title')}</h2>
                </a>
                <a href="/products">
                    <img src={fourImage3} alt="Our Products" className="four-image" />
                    <h2 className="image-title">{t('products_title')}</h2>
                </a>
                <a href="/contact">
                    <img src={fourImage4} alt="Contact Us" className="four-image" />
                    <h2 className="image-title">{t('contact_title')}</h2>
                </a>
            </div>


            <div className='manufacturing-process-section'>
                <div className="left-column">
                    <h1>{t('manufacturing_process_title')}</h1>
                    <p>{t('manufacturing_process_description')}</p>
                    <div className="ordered-list-container">
                        <div className="ordered-list-left">
                            <ol>
                                <li><strong>{t('selection_sorting')}</strong></li>
                                <li><strong>{t('peeling_rolling')}</strong></li>
                                <li><strong>{t('fumigation')}</strong></li>
                                <li><strong>{t('moisture_control')}</strong></li>
                            </ol>
                        </div>
                        <div className="ordered-list-right">
                            <ol start="5">
                                <li><strong>{t('quality_grading')}</strong></li>
                                <li><strong>{t('careful_packing')}</strong></li>
                                <li><strong>{t('final_touch')}</strong></li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="right-column">
                    <video autoPlay muted loop className="manufacturing-video">
                        <source src={manufacturingVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
            <div className="new-section">
                <div className="icon-container">
                    <div className="icon-item">
                        <img src={deliveryIcon} alt="Worldwide Delivery Icon" />
                        <h2>{t('global_shipping_title')}</h2>
                        <p>{t('global_shipping_description')}</p>
                    </div>
                    <div className="icon-item">
                        <img src={chatIcon} alt="24/7 Chat System Icon" />
                        <h2>{t('round_the_clock_chat_support_title')}</h2>
                        <p>{t('round_the_clock_chat_support_description')}</p>
                    </div>
                    <div className="icon-item">
                        <img src={sustainableIcon} alt="Sustainable Practices Icon" />
                        <h2>{t('eco_friendly_methods_title')}</h2>
                        <p>{t('eco_friendly_methods_description')}</p>
                    </div>
                    <div className="icon-item">
                        <img src={growthIcon} alt="Company Growth Icon" />
                        <h2>{t('business_expansion_title')}</h2>
                        <p>{t('business_expansion_description')}</p>
                    </div>
                </div>
            </div>
            <div className="new-image-section">
                <img src={newImage} alt="New Section" className="full-width-image" />
                <div className="rectangle-box">
                    <h1>{t('customer_feedbacks_title')}</h1>
                    <p>{t('customer_feedbacks_intro')}</p>
                    <div className="feedback-cards">
                        {feedbacksToDisplay.map((feedback, index) => (
                            <div key={index} className="feedback-card">
                                <p>"{feedback.feedback[i18n.language]}"</p>
                                <h3>{feedback.name[i18n.language]}</h3>
                                <p>{feedback.role[i18n.language]}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="faq">
                <h1>{t('faq_title')}</h1>
            </div>
            <div className="faq-section">
                <div className="faq-items">
                    {faqs.map((faq, index) => (
                        <div className="faq-item" key={index}>
                            <p className="faq-question" onClick={() => handleToggle(index)}>
                                {faq.question}
                                <span>{activeIndex === index ? '-' : '+'}</span>
                            </p>
                            {activeIndex === index && <div className="faq-answer">{faq.answer}</div>}
                        </div>
                    ))}
                </div>
                <div className="faq-image">
                    <img src={faqImage} alt="FAQ" />
                </div>
            </div>
            <motion.div
                className="other-companies-section"
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
            >
                <h1>{t('Other_companies')}</h1>
                <p>{t('start')}</p>
                <p>
                    <a href="https://www.bougainvilla.lk" target="_blank" rel="noopener noreferrer">
                        {t('link')}
                    </a>
                </p>
                <div className="other-companies-images">
                    <img src={otherCompanyImage1} alt="Company 1" />
                    <img src={otherCompanyImage2} alt="Company 2" />
                    <img src={otherCompanyImage3} alt="Company 3" />
                    <img src={otherCompanyImage4} alt="Company 4" />
                    <img src={otherCompanyImage5} alt="Company 5" />
                    <img src={otherCompanyImage6} alt="Company 6" />
                    <img src={otherCompanyImage7} alt="Company 7" />
                    <img src={otherCompanyImage8} alt="Company 8" />
                    <img src={otherCompanyImage9} alt="Company 9" />
                    <img src={otherCompanyImage10} alt="Company 10" />
                    <img src={otherCompanyImage11} alt="Company 11" />
                    <img src={otherCompanyImage12} alt="Company 12" />
                    <img src={otherCompanyImage13} alt="Company 13" />
                    <img src={otherCompanyImage14} alt="Company 14" />
                    <img src={otherCompanyImage15} alt="Company 15" />
                </div>
            </motion.div>
            <Footer />
        </div >
    );
}

export default Home;